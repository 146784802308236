<template>
    <b-modal id="modal-appointment-update" :title="modalTitle" hide-footer size="lg" centered>
        <div class="card-body pt-0">
            <form>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="status">Statut*</label>
                            <select 
                                v-model="selectedAppointment.status"
                                class="form-select"
                            >
                                <option v-for="status in ProspectService.statusList" :value="status.label" :key="status.label">{{ status.label }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <label for="comment">Commentaire</label>
                            <textarea
                                class="form-control"
                                v-model="selectedAppointment.comment"
                                rows="4"
                            ></textarea>
                        </div>
                    </div>
                    <div class="col-lg-12" v-if="showAdmin || showSuperSales">
                        <div class="mb-3">
                            <label for="comment">Commercial assigné</label>
                            <select class="form-select" v-model="chosenUser">
                                <option :value="user.id" v-for="user in users" :key="user.id">{{ user.firstname }} {{ user.lastname }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-12" v-if="showAdmin || showSuperSales">
                        <div class="custom-control custom-checkbox">
                            <div class="form-check">
                                <input v-model="selectedAppointment.closed" type="checkbox" class="form-check-input" id="closed">
                                <label class="form-check-label" for="closed">Reclasser le rendez-vous</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-end">
                            <button :disabled="processing" @click.prevent="onEditProspect()" class="btn btn-primary">
                                Modifier le rendez-vous
                                <i class="uil uil-pen ms-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </b-modal>
</template>

<script>
    import Swal from "sweetalert2";
    import api from "@/api/api";
    import Utilities from "@/services/utilities";
    import ProspectService from "@/services/prospect.service";

    export default {
        name: 'EditProspect',
        data() {
            return {
                ProspectService,
                errors: [],
                modalTitle: 'Modifier le rendez-vous',
                processing: false,
                chosenUser: null,
                users: [],
                selectedAppointment: {
                    status: null
                }
            }
        },
        props: ['showAdmin', 'showSuperSales', 'appointment'],
        watch: {
            appointment(el) {
                this.selectedAppointment = {...el};
                this.chosenUser = el.user.id;
                delete this.selectedAppointment.id;
            }
        },
        mounted() {
            if (this.showAdmin || this.showSuperSales) {
                this.loadUsers();
            }
        },
        methods: {
            appointmentValid() {
                this.errors = [];
    
                if (!this.selectedAppointment.status && this.selectedAppointment.status == "") {
                    this.errors.push('Le statut est obligatoire');
                }
    
                if (this.errors.length == 0) {
                    return true;
                } else {
                    Swal.fire("Erreur", this.errors[0], "error");
                    return false;
                }
            },
            loadUsers() {
                api
                    .get('/users')
                    .then(
                        (response) => {
                            if (response && response.status == 200) {
                                this.users = response.data;
                            }
                        },
                        () => {
                            Utilities.errormsg();
                        }
                    )
            },
            onEditProspect() {
                if (this.selectedAppointment.closed === true) {
                    Swal.fire({
                        title: "Attention, êtes-vous sûr de vouloir reclasser ce rendez-vous ?",
                        text: "Il ne sera plus possible de le modifier par la suite",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Reclasser",
                        cancelButtonText: "Annuler",
                    }).then((result) => {
                        if (result.value) {
                            this.editProspect();
                        }
                    });
                } else {
                    this.editProspect();
                }
            },
            editProspect() {
                if (this.processing === true) {
                    return;
                }
                
                this.processing = true;

                if (this.appointmentValid()) {
                    let data = {
                        status: this.selectedAppointment.status,
                        comment: this.selectedAppointment.comment
                    };

                    if (this.showAdmin || this.showSuperSales) {
                        data.closed = this.selectedAppointment.closed;
                        data.user = '/api/users/' + this.chosenUser;
                    }

                    api
                        .patch('/appointments/' + this.appointment.id, data, {
                            headers: {
                                'Content-Type': 'application/merge-patch+json'
                            }
                        })
                        .then(
                            (response) => {
                                if (response && response.status == 200) {
                                    Utilities.successmsg("Le rendez-vous a bien été modifié !");
                                    this.$emit('prospects-updated');
                                    this.processing = false;
                                }
                            },
                            () => {
                                Utilities.errormsg();
                                this.processing = false;
                            }
                        )
                }
            }
        }
    }
</script>
class CustomerService {
    statusList = [
        {
            label: "En cours",
            color: "primary"
        },
        {
          label: "Annulé",
          color: "warning"
        },
        {
          label: "Litige",
          color: "danger"
        },
        {
          label: "Dossier RGE",
          color: "light"
        }
    ]

    fullStatusList = [
        {
            label: "Prospect",
            color: "info"
        },
        {
            label: "En cours",
            color: "primary"
        },
        {
          label: "Annulé",
          color: "warning"
        },
        {
          label: "Litige",
          color: "danger"
        },
        {
          label: "Dossier RGE",
          color: "light"
        }
    ]

    getStatusColor(s) {
        var color = '';
        this.fullStatusList.map((status) => {
            if (status.label == s) {
                color = status.color;
            }
        });
        return color;
    }
}

export default new CustomerService();
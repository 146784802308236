<template>
    <b-modal id="modal-update" :title="modalTitle" hide-footer size="lg" centered>
        <div class="card-body pt-0">
            <form>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="firstname">Prénom*</label>
                            <input
                                v-model="customerToUpdate.firstname"
                                type="text"
                                class="form-control"
                                id="firstname"
                                placeholder="Ex : Dupont"
                            />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="lastname">Nom*</label>
                            <input
                                v-model="customerToUpdate.lastname"
                                type="text"
                                class="form-control"
                                id="lastname"
                                placeholder="Ex : Marie"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <label for="address">Adresse*</label>
                            <input
                                v-model="customerToUpdate.address"
                                type="text"
                                class="form-control"
                                id="address"
                                placeholder="Ex : 5 rue de Paradis"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="postcode">Code postal*</label>
                            <input
                                v-model="customerToUpdate.postcode"
                                v-mask="'#####'"
                                type="text"
                                class="form-control"
                                id="postcode"
                                placeholder="Ex : 75001"
                            />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="city">Ville*</label>
                            <input
                                v-model="customerToUpdate.city"
                                type="text"
                                class="form-control"
                                id="city"
                                placeholder="Ex : Paris"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="phone">Téléphone fixe</label>
                            <input
                                v-model="customerToUpdate.phone"
                                type="text"
                                class="form-control"
                                id="phone"
                                placeholder="Ex : 0100000000"
                            />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="mobile">Téléphone portable</label>
                            <input
                                v-model="customerToUpdate.mobile"
                                type="text"
                                class="form-control"
                                id="mobile"
                                placeholder="Ex : 0600000000"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="email">Email*</label>
                            <input
                                v-model="customerToUpdate.email"
                                type="email"
                                class="form-control"
                                id="email"
                                placeholder="Entrer un email"
                            />
                        </div>
                    </div>
                    <div class="col-lg-6" v-if="showAdmin || showSuperSales">
                        <div class="mb-3">
                            <label for="status">Statut*</label>
                            <select 
                                v-model="customerToUpdate.status"
                                class="form-select"
                            >
                                <option v-for="status in CustomerService.statusList" :value="status.label" :key="status.label">{{ status.label }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-end">
                            <button :disabled="processing" @click.prevent="editCustomer()" class="btn btn-primary">
                                Modifier le client
                                <i class="uil uil-pen ms-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </b-modal>
</template>

<script>
    import Swal from "sweetalert2";
    import api from '@/api/api';
    import Utilities from "@/services/utilities";
    import CustomerService from "@/services/customer.service";

    export default {
        name: 'EditCustomer',
        data() {
            return {
                CustomerService,
                errors: [],
                modalTitle: '',
                customerToUpdate: null,
                processing: false
            }
        },
        props: ['customer', 'showAdmin', 'showSuperSales'],
        created() {
            this.customerToUpdate = {...this.customer};
            delete this.customerToUpdate.id;

            this.modalTitle = 'Modifier une fiche client';
        },
        watch: {
            customer(el) {
                this.customerToUpdate = {...el};
                delete this.customerToUpdate.id;
            }
        },
        methods: {
            customerValid() {
                this.errors = [];
    
                if (!this.customerToUpdate.lastname) {
                    this.errors.push('Le nom est obligatoire');
                }
    
                if (!this.customerToUpdate.firstname) {
                    this.errors.push('Le prénom est obligatoire');
                }
    
                if (!this.customerToUpdate.address) {
                    this.errors.push('L\'adresse est obligatoire');
                }
    
                if (!this.customerToUpdate.postcode) {
                    this.errors.push('Le code postal est obligatoire');
                }
    
                if (!this.customerToUpdate.city) {
                    this.errors.push('La ville est obligatoire');
                }
    
                if (!this.customerToUpdate.phone && !this.customerToUpdate.mobile) {
                    this.errors.push('Au moins un numéro de téléphone est obligatoire');
                }
    
                if (this.errors.length == 0) {
                    return true;
                } else {
                    Swal.fire("Erreur", this.errors[0], "error");
                    return false;
                }
            },
            editCustomer: function () {
                if (this.customerValid()) {
                    if (this.processing === true) {
                        return;
                    }
                    
                    this.processing = true;

                    api
                        .patch('/customers/' + this.customer.id, this.customerToUpdate, {
                            headers: {
                                'Content-Type': 'application/merge-patch+json'
                            }
                        })
                        .then(
                            (response) => {
                                if (response && response.status == 200) {
                                    Utilities.successmsg("Le client a bien été modifié !");
                                    this.$emit('customer-updated');
                                    this.processing = false;
                                }
                            },
                            () => {
                                Utilities.errormsg();
                                this.processing = false;
                            }
                        )
                }
            },
        }
    }
</script>
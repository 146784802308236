<template>
    <Layout :show-admin="showAdmin" :show-super-admin="showSuperAdmin" :show-super-sales="showSuperSales">
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="row mt-3 flex-xs-column-reverse">
                    <div class="col-sm-12 col-md-6 mt-2">
                        <div id="tickets-table_filter" class="dataTables_filter">
                            <label class="d-inline-flex align-items-center fw-normal">
                                Recherche
                                <b-form-input v-model="filter" type="search" class="form-control form-control-sm ms-2"></b-form-input>
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div class="text-end">
                            <b-button variant="primary" v-b-modal.modal-add>
                                Créer un prospect
                                <i class="uil uil-plus ms-2"></i>
                            </b-button>
                            <b-modal id="modal-add" title="Créer un prospect" hide-footer size="lg" centered>
                                <AddProspect @prospects-updated="onProspectsUpdated" />
                            </b-modal>
                        </div>
                    </div>
                </div>
                <div class="table-responsive mb-0">
                    <b-table
                        table-class="table table-centered datatable table-card-list"
                        thead-tr-class="bg-transparent"
                        :items="prospectsData"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                    >
                        <template v-slot:cell(lastname)="data">
                            <div class="avatar-xs d-inline-block me-2">
                                <span class="avatar-title rounded-circle bg-light text-body">{{ data.item.customer.firstname.charAt(0) }}{{ data.item.customer.lastname.charAt(0) }}</span>
                            </div>

                            {{ data.item.customer.firstname }} {{ data.item.customer.lastname }}
                        </template>
                        <template v-slot:cell(status)="data">
                            <div 
                                class="badge bg-pill font-size-12"
                                :class="[`bg-${ProspectService.getStatusColor(data.item.status)}`]"
                            >{{ data.item.status }}</div>
                        </template>
                        <template v-slot:cell(createdDate)="data">
                            {{ SellService.getDate(data.item.createdDate) }}
                        </template>
                        <template v-slot:cell(user)="data">
                            {{ data.item.user.firstname }} {{ data.item.user.lastname }}
                        </template>
                        <template v-slot:cell(postcode)="data">
                            {{ data.item.customer.postcode }}
                        </template>
                        <template v-slot:cell(phone)="data">
                            {{ data.item.customer.phone }}
                        </template>
                        <template v-slot:cell(action)="data">
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item">
                                    <button class="btn px-2 text-primary" v-b-modal.modal-appointment-update content="Modifier le rendez-vous" v-tippy @click="onUpdateProspect(data.item)">
                                        <i class="uil uil-calendar-alt font-size-18"></i>
                                    </button>
                                </li>
                                <li class="list-inline-item">
                                    <button class="btn px-2 text-primary" v-b-modal.modal-update content="Modifier le client" v-tippy @click="onUpdateProspect(data.item)">
                                        <i class="uil uil-pen font-size-18"></i>
                                    </button>
                                </li>
                                <li class="list-inline-item" v-if="showAdmin || showSuperSales">
                                    <a href="javascript:void(0);" class="px-2 text-danger" content="Supprimer" v-tippy @click="onDeleteProspect(data.item.id)">
                                        <i class="uil uil-trash-alt font-size-18"></i>
                                    </a>
                                </li>
                            </ul>
                        </template>
                    </b-table>
                    <div class="text-center mb-5" v-if="!loading && rows === 0">
                        Il n'y a rien à afficher pour le moment. <a href="#" v-b-modal.modal-add>Créer un prospect</a> pour commencer à avoir des données.
                    </div>
                    <div class="text-center" v-if="loading">
                        <b-spinner class="align-middle" variant="primary"></b-spinner>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                            <label class="d-inline-flex align-items-center fw-normal">
                                <span class="pe-2">Afficher</span>
                                <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                                <span class="ps-2">entrées</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div class="dataTables_paginate paging_simple_numbers float-end">
                            <ul class="pagination pagination-rounded">
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <EditProspect @prospects-updated="onProspectsUpdated" :appointment="selectedProspect" :show-admin="showAdmin" :show-super-sales="showSuperSales" />
        <EditCustomer @customer-updated="onProspectsUpdated" :customer="selectedProspect ? selectedProspect.customer : null" :show-admin="showAdmin" :show-super-sales="showSuperSales" />
    </Layout>
</template>

<script>
    import Swal from "sweetalert2";
    import Layout from "@/views/layouts/main";
    import PageHeader from "@/components/page-header";
    import AddProspect from "@/forms/add-prospect";
    import EditProspect from "@/forms/edit-prospect";
    import appConfig from "@/app.config";
    import api from '@/api/api';
    import Utilities from "@/services/utilities";
    import SellService from "@/services/sell.service";
    import ProspectService from "@/services/prospect.service";
    import EditCustomer from "@/forms/edit-customer";

    /**
     * Prospect component
     */
    export default {
        components: { Layout, PageHeader, AddProspect, EditProspect, EditCustomer },
        page: {
            title: "Prospects",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        props: ["showAdmin", "showSuperAdmin", "showSuperSales"],
        data() {
            return {
                ProspectService,
                SellService,
                title: "Prospects",
                items: [{
                        text: "Accueil",
                        to: "/"
                    },
                    {
                        text: "Prospects",
                        active: true,
                    },
                ],
                prospectsData: [],
                totalRows: 1,
                currentPage: 1,
                perPage: 25,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "lastname",
                sortDesc: false,
                loading: true,
                selectedProspect: null,
                fields: [
                    {
                        key: "check",
                        label: "",
                    },
                    {
                        key: "lastname",
                        label: "Nom",
                        sortable: true,
                    },
                    {
                        key: "status",
                        label: "Status",
                        sortable: true
                    },
                    {
                        key: "createdDate",
                        label: "Date",
                        sortable: true
                    },
                    {
                        key: "postcode",
                        label: "Code postal",
                        sortable: true,
                    },
                    {
                        key: "phone",
                        label: "Téléphone",
                        sortable: true,
                    },
                    "action",
                ]
            };
        },
        computed: {
            rows() {
                return this.prospectsData.length;
            },
        },
        mounted() {
            this.totalRows = this.items.length;
            this.loadProspects();

            if (this.showAdmin) {
                this.fields.splice(2, 0, 
                    {
                        key: "user",
                        label: "Commercial",
                        sortable: true
                    }
                );
            }
        },
        methods: {
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            closeModal() {
                this.$bvModal.hide('modal-add');
                this.$bvModal.hide('modal-update');
                this.$bvModal.hide('modal-appointment-update');
            },
            onProspectsUpdated() {
                this.closeModal();
                this.loadProspects();
            },
            loadProspects() {
                api
                    .get('/appointments')
                    .then(
                        (response) => {
                            if (response && response.status == 200) {
                                if (Array.isArray(response.data)) {
                                    this.prospectsData = response.data;
                                    this.loading = false;
                                }
                            }
                        },
                        () => {
                            Utilities.errormsg();
                        }
                    )
            },
            onUpdateProspect(prospect) {
                this.selectedProspect = prospect;
            },
            onDeleteProspect(id) {
                Swal.fire({
                    title: "Attention",
                    text: "Êtes-vous sûr de vouloir supprimer ce rendez-vous ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Supprimer",
                    cancelButtonText: "Annuler",
                }).then((result) => {
                    if (result.value) {
                    api
                        .delete('/appointments/' + id)
                        .then(
                            (response) => {
                                if (response && response.status == 204) {
                                    Swal.fire({
                                    title: "Succès",
                                    text: "Le rendez-vous a bien été supprimé !",
                                    timer: 1500,
                                    icon: "success",
                                    confirmButtonColor: "#2e58a6",
                                    confirmButtonText: "Fermer",
                                    });
                                    this.loadProspects();
                                }
                            },
                            (error) => {
                                if (error.response.data.detail) {
                                    Utilities.alertmsg(error.response.data.detail);
                                    return;
                                }

                                Utilities.errormsg();
                            }
                        )
                    }
                });
            }
        },
        middleware: "authentication",
    };
</script>

<style lang="scss" scoped>
    @media all and (max-width: 576px) {
        .dataTables_filter::v-deep {
            label, input {
                width: 100%;
            }

            label {
                margin-top: 30px;
            }
        }

        .flex-xs-column-reverse {
            flex-direction: column-reverse;
        }
    }

    ::v-deep {
      th, td {
        white-space: nowrap;
      }
    }

    .bxs-star {
        color: #e5c100;
    }

    ::v-deep .table.b-table > thead > tr {
        & >[aria-sort] {
            cursor: pointer;
            background-image: none;
            background-repeat: no-repeat;
            background-size: 0.65em 1em;

            &:not(.b-table-sort-icon-left) {
                background-position: right 0.375rem center;
                padding-right: calc(0.75rem + 0.65em);
            }
        }

        & > [aria-sort=descending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
        }

        & > [aria-sort=ascending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
        }

        & > [aria-sort=none] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22zm0 100l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
        }
    }
</style>
class ProspectService {
    statusList = [
        {
            label: "A venir",
            color: "primary"
        },
        {
          label: "NRP",
          color: "danger"
        },
        {
          label: "ABS",
          color: "danger"
        },
        {
          label: "RDC",
          color: "danger"
        },
        {
          label: "Entrée sans suite",
          color: "success"
        }
    ]

    getStatusColor(s) {
        var color = '';
        this.statusList.map((status) => {
            if (status.label == s) {
                color = status.color;
            }
        });
        return color;
    }
}

export default new ProspectService();